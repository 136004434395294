import client from './axios';
import { GetPictureReq, GetPictureRes, ReqCheckReq, ReqCheckRes } from './types/verifition';

export const getPicture = (data: GetPictureReq) => {
  return client.post<GetPictureRes, GetPictureRes>(`/captcha/get`, data).then((res) => res);
};

export const reqCheck = (data: ReqCheckReq) => {
  return client.post<ReqCheckRes, ReqCheckRes>(`/captcha/check`, data).then((res) => res);
};
